import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzPriceDisplayComponent } from './kurz-price-display.component';
import { IconModule } from '@util/components/icon/icon.module';
import { I18nModule } from '@spartacus/core';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { KurzDifferentlyStyledValueModule } from '../kurz-differently-styled-value/kurz-differently-styled-value.module';
import { DifferentlyStyledStringParserPipesModule } from '../kurz-differently-styled-value/string-parser/pipes/differently-styled-string-parser-pipes.module';



@NgModule({
  declarations: [
    KurzPriceDisplayComponent
  ],
  imports: [
    CommonModule,
    ActivateElementModule,
    IconModule,
    I18nModule,
    KurzDifferentlyStyledValueModule,
    DifferentlyStyledStringParserPipesModule,
  ],
  exports: [KurzPriceDisplayComponent]
})
export class KurzPriceDisplayModule { }
